import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from './footer.module.scss'

export default () => (
  <StaticQuery
    query={graphql`
      query affliliationsQuery {
        allMarkdownRemark(
          filter: { fields: { contentType: { eq: "affiliations" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
                contentType
              }
              frontmatter {
                description
                title
                path
                photo {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <ul className={styles.list}>
        <li className={styles.listTitle}>Affiliations</li>      
        <li className={styles.divider}></li>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <li>
            {node.frontmatter.photo &&
              node.frontmatter.photo.childImageSharp && (
                <a href="https://www.hiphotels.com/hotels/asia/sri-lanka/kalukanda-house">
                  <Img
                    className={styles.affliliationImage}
                    fluid={node.frontmatter.photo.childImageSharp.fluid}
                    alt={node.frontmatter.title}                    
                  />
                </a>
              )}            
          </li>
        ))}
      </ul>
    )}
  />
)
