import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./footer.module.scss"
import logo from "../../static/assets/kalukanda-house-logo.svg"
import Affiliations from "./affliliations"

export default props => (
  <Container
    fluid
    className={props.extraSpace ? styles.containerSpaced : styles.container}
  >
    <Row>
      <Col md={4} xs={12}>
        <ul className={styles.list}>
          <li>
            <Link to="/">
              <img src={logo} alt="Logo" className={styles.logo} />
            </Link>
          </li>
          <li className={styles.divider}></li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/terms-conditions">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/cancellation-policy">Cancellation Policy</Link>
          </li>
          <li>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li>
        </ul>
      </Col>
      <Col md={4} xs={12}>
        <ul className={styles.list}>
          <li className={styles.listTitle}>Quick Links</li>
          <li className={styles.divider}></li>
          <li>
            <Link to="/experiences">Experiences</Link>
          </li>
          <li>
            <Link to="/the-villa">The Villa</Link>
          </li>
          <li>
            <Link to="/health-and-safety">Health and Safety</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/blog">Kalukanda Editorial</Link>
          </li>
          <li>
            <Link to="/ethos-on-sustainability">Ethos</Link>
          </li>
          <li>
            <Link to="/reviews">Guest Reviews</Link>
          </li>
        </ul>
      </Col>
      <Col md={4} xs={12}>
        <Affiliations />
      </Col>
    </Row>
    <Row>
      <Col>
        <p className={styles.copy}>
          All rights reserved &copy;Kalukanda House 2020
        </p>
      </Col>
    </Row>
  </Container>
)
