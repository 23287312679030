import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../../static/assets/kalukanda-house-logo.svg"
import { HamburgerVortex } from "react-animated-burgers"
import styles from "./headers.module.scss"
import Menu from "./menu.js"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default props => {
  var [isActive, setActive] = useState(false)

  function activate() {
    setActive(!isActive)
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.amburger}>
        <HamburgerVortex
          isActive={isActive}
          toggleButton={activate}
          barColor="white"
          buttonWidth={30}
        />
      </div>      
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="Logo" />
      </Link>      
      <div className={styles.buttonContainer}>
        <AnchorLink to="/#newsletter" className={styles.buttonSecondary}>
          Subscribe to Editorial
        </AnchorLink>
      </div>
      <Link to={"/booking"} className={styles.button}>
        Book Now
      </Link>
      {isActive && (
        <div className={styles.expandedMenu}>
          <Menu />
        </div>
      )}
    </div>
  )
}
