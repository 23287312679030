import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./menu.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default props => (
  <Container fluid className={styles.container}>
    <Row>
      <Col>
        <ul className={styles.list}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/experiences">Experiences</Link>
          </li>
          <li>
            <Link to="/ethos-on-sustainability">Sustainability</Link>
          </li>
          <li>
            <Link to="/the-villa">The Villa</Link>
          </li>
          <li>
            <Link to="/health-and-safety">Health and Safety</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/retreats">Retreats</Link>
          </li>
          <li>
            <Link to="/blog">Editorial</Link>
          </li>
          <li>
            <Link to="/reviews">Testimonials</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <AnchorLink to="/#newsletter">Subscribe to Editorial</AnchorLink>
          </li>
        </ul>
      </Col>
    </Row>
  </Container>
)
